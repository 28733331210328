:root {
    /* Primary Colors */
    --primary: #007bff;
    /* Blue */
    --primary-light: #66b2ff;
    --primary-dark: #0056b3;

    /* Secondary Colors */
    --secondary: #6c757d;
    /* Gray */
    --secondary-light: #a2a9b1;
    --secondary-dark: #494f54;


    /* Background Colors */
    --bg-light: #282A2D;
    --bg-dark: #0D0E10;


    /* Text Colors */
    --text-primary: #212529;
    --text-secondary: #6c757d;
    --text-light: #ffffff;
    --text-dark: #000000;
    --text-highlight: #D37B00;

    /* Button Colors */
    --btn-next: #007CB0;
    --btn-primary-bg: var(--primary);
    --btn-primary-text: var(--text-light);
    --btn-secondary-bg: var(--secondary);
    --btn-secondary-text: var(--text-light);

    /* Other */
    --border-color: #3C4043;
    --shadow-color: rgba(0, 0, 0, 0.15);
}

.fs-1 {
    font-size: 3rem;
}

.fs-2 {
    font-size: 2.5rem;
}

.fs-3 {
    font-size: 2rem;
}

.fs-4 {
    font-size: 1.5rem;
}

.fs-5 {
    font-size: 1.25rem;
}

.fs-6 {
    font-size: 1rem;
}

.fs-7 {
    font-size: 0.875rem;
}

.fs-8 {
    font-size: 0.75rem;
}

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.gap-4 {
    gap: 16px;
}

.gap-5 {
    gap: 20px;
}

body {
    font-family: 'Open Sans', sans-serif !important;
    background-color: var(--bg-dark);
}

.fw-thin {
    font-weight: 100;
}

.fw-extra-light {
    font-weight: 200;
}

.fw-light {
    font-weight: 300;
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-semi-bold {
    font-weight: 600;
}

.fw-bold {
    font-weight: 700;
}

.fw-extra-bold {
    font-weight: 800;
}

.fw-black {
    font-weight: 900;
}

.btn-next {
    background-color: var(--btn-next) !important;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    padding: 6px 16px;
}

.btn-next:hover {
    color: #fff;
}

.btn-cancel {
    background-color: var(--bg-dark) !important;
    color: #75787B;
    font-weight: 600;
    font-size: 13px;
    border: 1px solid var(--border-color) !important;
    padding: 6px 16px;
}

.btn-cancel:hover {
    color: #75787B;
}

.btn-xs {
    padding: 4px 10px !important;
    font-size: 12px !important;
}

.logo {
    width: 86px;
    border-right: 2px solid var(--border-color);
    margin-right: 1rem;
    ;
    padding-right: 1rem;
}

.mini_sidebar .top-navbar {
    height: 40px;
    width: 100% !important;
    padding: 2px 20px !important;
    margin: 0px !important;
    background: var(--bg-dark);
    border-bottom: 2px solid var(--border-color);
}

.text-highlight {
    color: var(--text-highlight);
    text-transform: capitalize;
    font-weight: 600;
}

.flex-align {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-user-align {
    border-left: 2px solid var(--border-color);
    margin-left: 0.5rem;
    ;
    padding-left: 0.5rem;
}

.nav-user img {
    width: 100%;
    object-fit: contain;

}

.navbar-nav>li {
    margin: 0px 4px;
}

.sidenav_bg {
    background-color: var(--bg-dark);
    border-right: 2px solid var(--border-color);
}

.metismenu a:active,
.metismenu a:focus,
.metismenu .active a {
    background-color: var(--bg-light);
    border-right: 0px solid #3aafa9;
    border-radius: 0px !important;
}

#left-sidebar {
    height: calc(100vh - 40px);
    margin-top: 40px;
}

.navbar-brand {
    padding: 0px;
    margin: 0px;
}

.custom-tabs {
    list-style: none;
    padding: 1.5rem 0 0 0;
    display: flex;
    justify-content: left;
    gap: 0.5rem;
    background-color: var(--bg-light);
    border-bottom: 2px solid var(--border-color);
}

.custom-tabs-link {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    position: relative;
    transition: color 0.3s ease;
    font-size: 12px;
    font-weight: 600;
}

.custom-tabs-link::after {
    content: "";
    display: block;
    width: 0;
    height: 5px;
    background-color: #86BC25;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.3s ease;
}

.custom-tabs-link.active::after {
    width: 50%;
}

#main-content {
    margin-top: 40px !important;
}

.mini_sidebar #main-content {
    width: calc(100% - 60px);
    padding: 0 0px;
}

/* --------------------F TABLE STYLES START-------------------------- */

.f-table {
    font-size: 12px;
    color: #fff;
    font-weight: 100;
    overflow: auto;
}

.f-table thead {
    background-color: var(--bg-light) !important;
    border-bottom: 1px solid var(--border-color);
}

.f-table thead tr {
    border-bottom: 2px solid var(--border-color);
}

.f-table thead th {
    font-weight: 600 !important;
    background-color: var(--bg-light) !important;
}




.f-table tbody tr {
    border-bottom: 1px solid var(--border-color);
}
.f-table th,tr {
border: none ;
}
.table-container {
    max-height: 460px;
    overflow-y: auto;
    position: relative
}


.table-container::-webkit-scrollbar {
    display: none;
}


.table-container {
    scrollbar-width: none;
}


.table-container {
    -ms-overflow-style: none;
}

.head-sticky {
    position: sticky;
    top: -2;
    z-index: 1;
}

.res-table::-webkit-scrollbar {
    height: 8px !important;
    width: 8px;
    background-color: transparent;
}

.res-table::-webkit-scrollbar-thumb {
    background-color: var(--bg-light) !important;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.res-table::-webkit-scrollbar-track {
    background-color: var(--bg-light) !important;
}

.res-table:hover::-webkit-scrollbar {
    background-color: var(--bg-light);
}

.res-table:hover::-webkit-scrollbar-thumb {
    background-color: var(--bg-light) !important;
}

.res-table {
    scrollbar-color: var(--bg-dark) var(--bg-light) !important;
    scrollbar-width: thin;
}



.f-table-striped {
    border-right: 5px solid var(--bg-dark) !important;
}

.actions-list {
    display: flex;
    align-items: center;
    gap: 8px;
}

.f-table .input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    width: 80px;
}

.f-table .input-group input {

    text-align: center;
    border: 1px solid var(--border-color);
    background: var(--bg-dark);
    color: #fff !important;
    padding: 5px;
    border-radius: 5px;
    height: 36px;
    margin-left: 6px;
}

.f-table .input-group-text {
    color: #fff;
    border: 1px solid var(--border-color);
    padding: 5px;
    border-radius: 0px;

    height: 36px;
    width: 32px;
    text-align: center;
    background-color: var(--bg-light) !important;
    display: grid;
    place-items: center;

}

.right-top-redius {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.left-top-redius {

    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.m-auto {
    margin: 0 auto !important;
}
.table-heading{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
}
/* --------------------F TABLE STYLES END-------------------------- */


/* --------------------FILTER SECTION STYLES START-------------------------- */
.filter-section {
    display: flex;
    align-items: center;
    gap: 4px;
    /* Spacing between icons */
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
}

.filter-section .filter-item {
    width: 36px;
    height: 36px;
    background-color: var(--bg-light);
    /* Default background */
    border-radius: 50%;
    /* Circular shape */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.filter-section .filter-item img {
    width: 16px;
    /* Adjust icon size */
    height: auto;
    filter: brightness(0) invert(1);
    /* Makes icons white */
}

.filter-section .filter-item:hover {
    background-color: #53565A;
    /* Slightly lighter on hover */
}

/* Active state */
.filter-section .filter-item.active {
    background-color: #53565A;
    /* Active background color */
}

.filter-section .filter-item.active img {
    filter: brightness(0) invert(1);
}

.filter-icons img {
    background-attachment: fixed;
}


.icon-container {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    margin-bottom: 1rem;
}

.icon-wrapper {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-light);
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
}

.icon-wrapper:hover {
    background-color: var(--border-color);
    cursor: pointer;
}

.icon-wrapper img {
    width: 16px;
    height: 16px;
}

.rotate-180 {
    transform: rotate(180deg);
}


/* --------------------FILTER SECTION STYLES END-------------------------- */

/* --------------------FORM STYLES START-------------------------- */

.f-form label {
    font-size: 12px;
    font-weight: 600;
    color: #F0F0F0;
    margin-bottom: 10px;
}

.f-form .form-control {
    font-size: 12px !important;
    height: 34px !important;
    color: #F0F0F0 !important;
    background-color: var(--bg-dark);
    ;
}

.f-form select.form-control {
    padding-right: 2rem;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 6l5 5 5-5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
}



/* Styling select dropdown */
.f-form select {
    background-color: var(--bg-dark);
    color: #fff;
}

.f-check-box {
    display: flex;
    align-items: center;
    gap: 6px 40px;
    flex-wrap: wrap;
}

.form-check input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-check label {
    position: relative;
    cursor: pointer;
}




.custom-check-box label {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
}

.custom-check-box input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: transparent;
    cursor: pointer;
    position: relative;
}


.custom-check-box input[type="checkbox"]:checked::before {
    content: '✔';
    font-size: 16px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.custom-check-box input[type="checkbox"]:checked {
    background: var(--btn-next);
    border-color: var(--btn-next);
}

/* --------------------FORM STYLES END-------------------------- */


/* --------------------LOGIN PAGE STYLES START-------------------------- */

.login-page {
    background-image: url(../../assets/images/login-bk.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    /* Centers content vertically & horizontally */
    height: 100vh;
}

.login-logo {
    width: 120px;
    margin-bottom: 1.5rem;
}

.login-title {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}

.login-label {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
}

#login-form .form-control {
    font-size: 14px !important;
    color: #F0F0F0 !important;
    background-color: var(--bg-light);
    ;
}


#login-form input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #ff9800;
}


.remember-label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
}

.remember-label label {
    margin-bottom: 0px;
}

/* --------------------LOGIN PAGE STYLES END-------------------------- */


/* --------------------SEARCH BOX STYLES START-------------------------- */

.search-box {
    display: flex;
    align-items: center;
    background-color: var(--bg-light);
    border-radius: 20px;
    padding: 5px 10px;
    width: 250px;
    height: 32px;
}

.search-input {
    flex: 1;
    border: none;
    background: transparent;
    color: #fff;
    padding: 8px;
    outline: none;
    font-size: 12px;
}

.search-input::placeholder {
    color: #ccc;
}

.search-button {
    border: none;
    background: transparent;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    padding: 5px;
}

.search-button:hover {
    color: #ddd;
}

/* --------------------SEARCH BOX STYLES END-------------------------- */

/* --------------------CATALOGUE  STYLES END-------------------------- */
.catalogue-names {
    display: flex;
    gap: 6px;
}

.catalogue-item {
    display: flex;
    align-items: center;
    background: var(--bg-dark);
    border: 1px solid var(--border-color);
    padding: 3px 5px;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
}

.catalogue-item.active {
    background: #555;
}

.catalogue-item .icon {
    margin-right: 4px;
    font-size: 14px;
}

.catalogue-item:hover {
    background: #222;
}

.check-box {
    width: 16px;
    height: 16px;
    cursor: pointer;
    vertical-align: bottom;
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.w-20 {
    width: 20px;
}

.w-50 {
    width: 50px !important;
}

.w-60 {
    width: 60px !important;
}

/* --------------------CATE BOX STYLES END-------------------------- */


.container2 {
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.vertical-align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 500;
    color: #444444;
    padding: 20px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}


/* --------------------FOOTER PAGE STYLES START-------------------------- */
.footer-section {
    background-color: var(--bg-dark);
}

.footer-align {
    width: -webkit-fill-available;
    position: fixed;
    bottom: 0;
    border-top: 2px solid var(--border-color);
    background-color: var(--bg-dark);

}

.footer-icons {
    display: flex;
    align-items: center;
    gap: 6px;
}

/* --------------------FOOTER PAGE STYLES END-------------------------- */

/* --------------------CATEGORY PAGE STYLES START-------------------------- */
.f-service-category {
    height: 100vh;
    width: 100vw;
    background-image: url(../../assets/images/f-app-bk.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.f-category-head {
    width: 100%;
    background-color: var(--bg-light);
    padding: 10px 0px;
}

.f-category-head img {
    width: 160px;
}

.f-categories {
    display: flex;
    align-items: center;
    gap: 12px;
}

.f-category-item {
    background-color: var(--bg-light);
    border: 2px solid var(--border-color);
    border-radius: 4px;
    padding: 0.9rem;
    text-align: center;
    display: grid;
    place-items: center;
    width: 120px;
    height: 152px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    color: #fff;
    font-weight: 300;
}


.f-category-item:hover {
    transform: scale(1.07);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.f-category-item img {
    width: 58px;
    transition: transform 0.3s ease-in-out;
}

.f-category-item h6 {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 400;
}

.f-service-category .footer-align {
    background-color: rgba(40, 42, 45, 0.8);
}

/* --------------------CATEGORY PAGE STYLES END-------------------------- */

/* --------------------FILTER CONTAINER  STYLES START-------------------------- */
.f-filter-container {
    background-color: var(--bg-light);
    border-bottom: 2px solid var(--border-color);
    padding: 1rem;
    margin-bottom: 0.6rem;
    flex-wrap: wrap;
    font-size: 12px;
    color: var(--secondary);
}



.f-filter-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    align-items: baseline;
}

.f-filter-item {
    position: relative;
    border-right: 1px solid var(--border-color);
    height: -webkit-fill-available;
}

.f-filter-item:last-child {
    border-right: 0px solid var(--border-color);
}

.f-filter-item .input-group {
    display: flex;
    gap: 10px;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.f-filter-item .input-group input {
    background: var(--bg-light);
    border: 1px solid var(--border-color);
    color: #fff;
    padding: 5px;
    width: 80px;
    text-align: center;

}

.f-filter-item .apply-btn {
    background-color: var(--btn-next);
    border: none;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}



.f-filter-item-name {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.6rem;
}

.f-filter-item .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.f-green {
    background-color: #02BC77;
}

.f-red {
    background-color: #E14B4B;
}

.f-table thead th:last-child .filter-popup {
    position: absolute;
    background: var(--bg-light);
    padding: 12px;
    top: 39px;
    right: 0;
    z-index: 1000;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.filter-popup {
    position: absolute;
    background: var(--bg-light);
    padding: 12px;
    top: 39px;
    z-index: 1000;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    display: grid;
    gap: 10px;
}

.filter-input {
    padding: 5px;
    background-color: var(--bg-light) !important;
    border: 1px solid var(--border-color) !important;
    color: #fff !important;
    width: 192px;
}

.filter-input:focus {
    color: #fff !important;
    background-color: var(--bg-dark) !important;
    border: 1px solid var(--border-color) !important;
    outline: none;
}

.date-filter input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.upload-file-container input[type="file"] {
    background-color: var(--bg-dark);
    color: var(--secondary) !important;
    border: 2px solid var(--border-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-file-container input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.upload_document {
    border: dashed 2px var(--border-color);
}
.tag-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.tag-popup .filter-popup{
    width: 350px;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px;
}
.tag-popup .filter-popup .filter-input {
    width: 100%;
    font-size: 12px;
    padding: 5px;
}
/* --------------------FILTER CONTAINER  STYLES END-------------------------- */

/* --------------------PAGINATION CONTAINER  STYLES START-------------------------- */
.pagination-container .pagination li {
    margin: 0px 3px !important;
}


.pagination-container .pagination li a:first-child,
.pagination-container .pagination li a:last-child {
    content: "" !important;
    border: none !important;
    width: auto;
    height: auto;
    display: inline-block;
    font-size: small;
    margin: 0px 2px;
    padding: 5px;
    color: var(--secondary);
    font-weight: bold;
    background: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


.pagination-container .pagination li a:first-child:hover,
.pagination-container .pagination li a:last-child:hover {
    color: #fff;
    background: transparent  !important;
    border-radius: 5px;
    padding: 5px 5px;
}


.pagination-container .pagination li a:first-child.active,
.pagination-container .pagination li a:last-child.active {
    background: var(--secondary);
    color: #fff;
    border-radius: 5px;
}

.pagination-container .pagination li a {
    border: 2px solid var(--border-color) !important;
    width: 22px;
    height: 22px;
    border-radius: 22px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--secondary);
    font-size: 12px;
    line-height: 13px;
    text-align: center;
}

.pagination-container .pagination li.active a,
.pagination li.active a:hover {
    background-color: var(--btn-next) !important;
    width: 22px;
    height: 22px;
    border-radius: 22px !important;
    outline: none !important;
    border: 2px solid var(--btn-next) !important;
    color: #fff !important;
    font-size: 12px;
    line-height: 13px;
}

.pagination-container .pagination .active,
.pagination .active a {
    border: 0px !important;
}
.pagination-container .pagination .previous a {
    font-size: 0 !important; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container .pagination .previous a::before {
    content: "\f104"; 
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 16px; 
}

.pagination-container .pagination .next a {
    font-size: 0 !important; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-container .pagination .next a::before {
    content: "\f105"; 
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 16px; 
}


/* --------------------PAGINATION CONTAINER  STYLES END-------------------------- */