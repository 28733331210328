.language-menu {
    box-shadow: none;
    border-color: #fff !important;
}

.login-imagenew {
    max-width: 350px;
}

.language-menu:hover {
    box-shadow: none;
    border-color: #fff !important;
}

.auth-main .WorkspaceText {
    font-size: 18px;
    font-weight: 600;
}

.auth-main .ProvideText {
    font-size: 14px;
    font-weight: 500;
}


.loginbg .auth-main {
    height: 100%;
}
.PswVeiw-btn{
    background: none !important;
    border: none !important;
    padding: 0px !important;
    cursor: pointer !important;
    right: 10px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

@media only screen and (min-width: 999px) and (max-width: 1450px) {

    .login-imagenew {
        max-width: 300px;
    }

    .auth-main .WorkspaceText {
        font-size: 18px;
        font-weight: 600;
    }

    .auth-main .ProvideText {
        font-size: 14px !important;
        font-weight: 500;
    }
}


@media only screen and (min-height: 150px) and (max-height: 346px) {
     
        .auth-main {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%!important;
        }
   
}